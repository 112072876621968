<template>
  <div>
    <!--File Upload Progress Bar-->
    <v-dialog v-model="upload" max-width="290" persistent hide-overlay>
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          File Uploading
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn :disabled="fileImporting" icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-cloud-upload
          </v-icon>
        </v-btn>
      </template>
      <span>Upload Waiting List Data</span>
    </v-tooltip>

    <!--Document Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="600">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Waiting List Data Upload<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" @submit.prevent="saveEntry()" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--File-->
                <v-col cols="12" sm="12">
                  <v-file-input
                    ref="file"
                    v-model="files"
                    clearable
                    clear-icon="fal fa-times-circle"
                    prepend-icon="fal fa-paperclip"
                    label="Select File"
                    :rules="rules.files"
                    required
                    placeholder=" "
                    persistent-placeholder
                  >
                  </v-file-input>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn block rounded dark color="green lighten-1" @click="saveEntry()"
            ><v-icon small left>fal fa-cloud-upload</v-icon>Upload</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import { uploadHeader } from "@/env";

const { mapFields } = createHelpers({
  getterType: "waitingListStore/getUploadField",
  mutationType: "waitingListStore/updateUploadField"
});

export default {
  name: "WaitingListImageUpload",
  mixins: [mixin],
  computed: {
    ...mapState({
      waitingListStore: state => state.waitingListStore
    }),
    ...mapFields(["show", "valid"])
  },
  data() {
    return {
      files: [],
      upload: false,
      fileImporting: false,
      rules: {
        files: [
          v => !!v || "File is required"
          //v => (v && v.length > 0) || "File is required"
        ]
      }
    };
  },
  methods: {
    initialize() {},

    async newEntry() {
      this.show = 1;
    },

    openSnackbar(code) {
      let data;
      if (code === 1) {
        data = {
          color: "green lighten-1",
          text: "Waiting List data file has been successfully uploaded."
        };
      } else if (code === 2) {
        data = {
          color: "error",
          text:
            "There was an error uploading the file.  Try again and contact support if the issue continues."
        };
      }
      this.$store.commit("messagesStore/setMessage", data);
    },

    cancelEntry() {
      (this.upload = false), (this.show = false);
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        this.fileImporting = true;

        this.file = this.files;
        const formData = new FormData();
        formData.append("file", this.file);
        this.upload = true;
        this.cancelEntry();
        const response = await axios.post("/api/waitinglist/import", formData, {
          headers: uploadHeader()
        });
        this.upload = false;
        if (response.status === 200) {
          this.$refs.entryForm.reset();
          this.openSnackbar(1);
        } else {
          this.openSnackbar(2);
        }
        this.$store.dispatch("progressStore/set", true);

        const filters = this.$store.getters["waitingListStore/getFilters"];
        await this.$store.dispatch("waitingListStore/fetch", filters);

        this.fileImporting = false;
        this.$store.dispatch("progressStore/set", false);
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
