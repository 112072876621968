<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="waitingListSection === 1" :title-value="'Waiting List'" />

    <!-- Filter -->
    <v-toolbar v-if="waitingListSection !== 2" flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card max-width="600px">
          <v-card-text>
            <v-container fluid>
              <!--Clear Filter Button-->
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--WLS ID-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_wlsID"
                    label="WLS ID"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Initial Specialist-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameInitialCounselor"
                        label="Initial Specialist"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Parent Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLast"
                        label="Parent Last Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Priority-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.valueListItems[39]"
                        item-text="d_name"
                        item-value="d_name"
                        label="Priority"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_priority"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Residence County-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="countiesStore.countiesValueList"
                        item-text="d_name"
                        item-value="d_name"
                        label="County of Residence"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_residenceCounty"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                  </v-row>

                  <v-row dense>
                    <!--Current Specialist-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="usersStore.usersValueList"
                        label="Specialist"
                        placeholder=" "
                        persistent-placeholder
                        item-text="name"
                        item-value="id"
                        v-model="fk_counselorID"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Parent First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameFirst"
                        label="Parent First Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Income Rank-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="f_incomeRank"
                        label="Income Rank"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Alert-->
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        :items="actionTypesStore.actionTypesValueList"
                        item-text="d_name"
                        item-value="id"
                        label="Alert"
                        placeholder=" "
                        persistent-placeholder
                        v-model="fk_actionTypeID"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12">
                  <v-autocomplete
                    :items="valueListsStore.valueListItems[45]"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="d_name"
                    item-value="d_value"
                    v-model="f_status"
                    clearable
                    clear-icon="fal fa-times-circle"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <!--Submit Button-->
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <export-excel
        :section="46"
        :enabled="waitingListStore.waitingList.data && waitingListStore.waitingList.data.length > 0"
      />
      <waiting-list-upload />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="waitingListStore.waitingList.data && waitingListStore.waitingList.data.length > 0"
      :showTotal="waitingListSection === 2 ? false : true"
      :currentPage="waitingListStore.waitingList.current_page"
      :lastPage="waitingListStore.waitingList.last_page"
      :total="waitingListStore.waitingList.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--List-->
      <list-to-detail
        :show="waitingListStore.waitingList.data && waitingListStore.waitingList.data.length > 0"
        :list-data="waitingListStore.waitingList.data"
        :title="'Waiting List Items'"
        :section="26"
        :showid="false"
        @open-detail="detail($event)"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import ListToDetail from "@/components/ListToDetail";
import ExportExcel from "@/components/ExportExcel";
import WaitingListUpload from "@/components/WaitingListUpload";

const { mapFields } = createHelpers({
  getterType: "waitingListStore/getFilterField",
  mutationType: "waitingListStore/updateFilterField"
});

export default {
  name: "WaitingListItems",
  components: {
    TitleBar,
    Pagination,
    ListToDetail,
    ExportExcel,
    WaitingListUpload
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      waitingListStore: state => state.waitingListStore,
      progressStore: state => state.progressStore,
      countiesStore: state => state.countiesStore,
      usersStore: state => state.usersStore,
      valueListsStore: state => state.valueListsStore,
      actionTypesStore: state => state.actionTypesStore
    }),
    ...mapFields([
      "page",
      "section",

      "d_wlsID",
      "d_priority",
      "f_incomeRank",
      "d_nameFirst",
      "d_nameLast",
      "d_residenceCounty",
      "fk_counselorID",
      "d_nameInitialCounselor",
      "fk_actionTypeID",
      "f_status",
    ])
  },
  props: {
    /* All = 1, Dashboard = 2 */
    waitingListSection: {
      type: Number,
      required: false
    }
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);

      await this.changeSectionCheck();

      const filters = this.$store.getters["waitingListStore/getFilters"];
      await this.$store.dispatch("waitingListStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.waitingListSection !== this.section) {
        this.clearFilterColumns();
        this.$store.dispatch("waitingListStore/clearWaitingList");
      }
      /* Save section in store */
      this.section = this.waitingListSection;
    },

    async loadValueLists() {
      /* Pull counselor value list */
      const specialist = {
          f_counselor: 1
        };
      await this.$store.dispatch("usersStore/valueList", specialist);
      const f_type = {
        f_type: 4
      };
      await this.$store.dispatch("actionTypesStore/valueList", f_type);

      /* Pull priority and status value list */
      const data = {
        id: [39, 45]
      };
      await this.$store.dispatch("valueListsStore/items", data);

      /* Pull counties value list*/
      const counties = this.$store.getters["countiesStore/getCountyValueList"];
      if (counties.length === 0) {
        await this.$store.dispatch("countiesStore/valueList");
      }
    },

    async filter(page) {
      this.filterMenu = false;
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_wlsID = "";
      this.d_priority = "";
      this.f_incomeRank = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.d_residenceCounty = "";
      this.fk_counselorID = "";
      this.d_nameInitialCounselor = "";
      this.fk_actionTypeID = "";
      this.f_status = "";
      this.page = 1;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_wlsID ||
        this.d_priority ||
        this.f_incomeRank ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.d_residenceCounty ||
        this.fk_counselorID ||
        this.d_nameInitialCounselor ||
        this.fk_actionTypeID ||
        this.f_status ||
        this.f_status === 0
      ) {
        return true;
      }
      return false;
    },

    detail(uuid) {
      this.$store.dispatch("waitingListStore/currentIndex", uuid);
      this.$router.push({ name: "waitingListItem", params: { waitingListUUID: uuid } });
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
