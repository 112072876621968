<template>

  <div>

    <waiting-list-items :waitingListSection="1"/>

  </div>

</template>

<script>
import WaitingListItems from '@/components/WaitingListItems';

export default {
  name: 'WaitingList',
  components: {
    WaitingListItems,
  }
};
</script>

<style scoped>

</style>
